<template>
  <div>
    <div
      v-if="invoice.Code"
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 15px"
    >
      <div class="vx-col sm:w-1/3 w-full">
        <span>Invoice Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ invoice.Code }}
      </div>
    </div>

    <div
      v-if="invoice.ClientCode"
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 15px"
    >
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        {{ invoice.ClientCode }}
      </div>
    </div>
    <div v-else class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Customer</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.customer"
          :options="option.customer"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          @select="selectedCustomer"
          placeholder="Type to search"
          track-by="code"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ props.option.code }}) {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div  v-if="!invoice.ClientCode" class="vx-row margin-btm" style="width: 100%;padding-bottom:15px;">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Sales Order CBD</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.salesOrder"
          :options="option.salesOrder"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="Code"
          label="Code"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ props.option.Code }}) {{ props.option.name }}</span
              >
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ props.option.Code }}) {{ props.option.name }}</span
              >
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row margin-btm" style="width: 100%" v-if="mode != 'deposit'">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Payment Account</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="create.paymentAccount"
          :options="option.paymentAccount"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="120"
          :limit="3"
          placeholder="Type to search"
          track-by="code"
          label="AccountName"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title"
                >({{ paymentMethod[props.option.Type] }}
                {{ props.option.Issuer }} - {{ props.option.AccountNumber }})
                {{ props.option.AccountName }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title"
                >({{ paymentMethod[props.option.Type] }}
                {{ props.option.Issuer }} - {{ props.option.AccountNumber }})
                {{ props.option.AccountName }}
              </span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <br v-if="mode != 'deposit'" />
    <div class="vx-row margin-btm" style="width: 100%" v-if="mode != 'deposit'">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Reference Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input class="w-full" v-model="create.referenceCode" />
      </div>
    </div>
    <br v-if="mode != 'deposit'" />
    <div
      v-if="invoice.Total"
      class="vx-row margin-btm"
      style="width: 100%; padding-bottom: 15px"
    >
      <div class="vx-col sm:w-1/3 w-full">
        <span>Amount</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="create.amount"
          :disabled="create.disable"
          @keyup="create.amount = formatPrice(create.amount.toString())"
        />
      </div>
    </div>
    <div v-else class="vx-row margin-btm" style="width: 100%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>Amount</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input
          class="w-full"
          v-model="create.amount"
          :disabled="create.disable"
          @keyup="create.amount = formatPrice(create.amount.toString())"
        />
      </div>
    </div>
    <br />

    <div class="vx-row margin-btm" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>

      <vs-button @click="handleSubmit()" icon-pack="feather" icon="icon-save">
        Submit
      </vs-button>
    </div>
  </div>
</template>
<script>
import {
  dataCustomer,
  dataPaymentAccount,
  postPayment,
  postInvoiceDeposit,
  updateInvoiceDeposit,
} from "../../../../services/api/payment";
export default {
  props: {
    mode: String,
    invoice: Object,
  },
  data() {
    return {
      // 1: Real Account,2: VA, 3: Wallet
      paymentMethod: ["", "Real Account", "VA", "Wallet"],
      create: {
        referenceCode: null,
        customer: null,
        paymentAccount: null,
        salesOrder:null,
        amount: 0,
        notes: null,
        disable: false
      },
      option: {
        customer: [],
        salesOrder: [],
        paymentAccount: [],
      },
    };
  },
  mounted() {
    this.getCustomer();
    if (this.invoice.ID != 0) {
      this.create.customer = {code :this.invoice.ClientCode, name: this.invoice.ClientName }
      this.getPaymentAccount();
      if (this.invoice.IsCbd) {
          this.create.disable = true
      }
      this.create.amount = this.formatPrice(this.invoice.Total.toString());
    }
    if (this.mode == "deposit" && this.invoice.ID != 0 ) {
      if (this.invoice.IsCbd) {
          this.create.disable = true
      }
      this.create.amount = this.formatPrice(this.invoice.Total.toString());
    }
  },
  watch: {
    "create.customer": function () {
      this.getPaymentAccount();
      this.create.salesOrder = null
    },
    "create.salesOrder": function () {
      try {
        if(this.create.salesOrder.Total > 0) {
          this.create.disable = true
          this.create.amount = this.formatPrice(this.create.salesOrder.Total.toString())
        } 
      } catch (err) {
        this.create.disable = false
        this.create.amount = 0
      }
    },
    "invoice.ID": function () {
      console.log(this.invoice.IsCbd)
      if (this.invoice.IsCbd) {
          this.create.disable = true
      }
    },
  },
  methods: {
    selectedCustomer(selected) {
       this.$http
      .get("/api/v1/payment/customer/sales-order-cbd", {
        params: {
          customer_id: selected.ID
        },
      })
      .then((resp) => {
        console.log(resp)
        if (resp.status == "success") {
          this.option.salesOrder = resp.data.salesOrder
        }
      });
    },
    closeDetail(params = false) {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
      if (params == true) {
        this.reloadData(this.params);
      }
    },
    handleSubmit() {
      this.$vs.loading();
      console.log(this.create.amount)
      let post;
      if (this.mode != "deposit") {
        const request = {
          type: 2,
          customer_code: this.create.customer.code,
          customer_name: this.create.customer.name,
          payment_method: this.paymentMethod[this.create.paymentAccount.Type],
          payment_name: this.create.paymentAccount.Issuer,
          payment_account_owner: this.create.paymentAccount.AccountName,
          payment_account_number: this.create.paymentAccount.AccountNumber,
          reference_code: this.create.referenceCode,
          amount: parseInt(
            this.create.amount.replace(/[^,\d]/g, "").toString()
          ),
          status: 0,
          invoice_id: this.invoice.ID
        };
        post = postPayment(request);
      } else {
        if (this.invoice.ID == 0) {
          const request = {
            type: 2,
            customer_code: this.create.customer.code,
            customer_name: this.create.customer.name,
            sales_order: this.create.salesOrder.Code,
            // payment_method: this.paymentMethod[this.create.paymentAccount.Type],
            // payment_name: this.create.paymentAccount.Issuer,
            // payment_account_owner: this.create.paymentAccount.AccountName,
            // payment_account_number: this.create.paymentAccount.AccountNumber,
            // // reference_code: this.create.referenceCode,
            amount: parseInt(
              this.create.amount.replace(/[^,\d]/g, "").toString()
            ),
            status: 0,
          };
          post = postInvoiceDeposit(request);
        } else {
          const request = {
            type: 2,
            amount: parseInt(
              this.create.amount.toString().replace(/[^,\d]/g, "").toString()
            ),
            status: 0,
            invoice_id: this.invoice.ID
          };
          post = updateInvoiceDeposit(request);
        }
      }
      post.then((r) => {
        if (r.code == 500) {
          this.$vs.notify({
            title: "Failed",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "warning",
          });
        } else if (r.code == 200) {
          this.$vs.notify({
            title: "Success",
            text: r.message,
            iconPack: "feather",
            position: "top-right",
            icon: "icon-alert-circle",
            color: "success",
          });
        }
        this.$nextTick(() => {
          // this.$emit("closeDetail");
          this.$emit("closeDetail", true);
          this.$vs.loading.close();
        });
      });
    },
    formatPrice(angka, prefix = "") {
      console.log(angka);
      var number_string = angka.replace(/[^,\d]/g, "").toString();
      var split = number_string.split(",");
      split[0] = parseInt(split[0]).toString();
      var sisa = split[0].length % 3;
      var rupiah = split[0].substr(0, sisa);
      var ribuan = split[0].substr(sisa).match(/\d{3}/gi);
      var separator = "";
      if (ribuan) {
        if (sisa) {
          separator = ".";
        } else {
          separator = "";
        }
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
    },
    getCustomer() {
      this.$vs.loading();
      const customer = dataCustomer();
      customer.then((response) => {
        this.option.customer = [];
        response.data.customer.map((row) => {
          this.option.customer.push(row);
        });
        this.$vs.loading.close();
      });
    },
    getPaymentAccount() {
      this.$vs.loading();
      const paymentAccount = dataPaymentAccount(this.create.customer.code);
      paymentAccount.then((response) => {
        this.create.paymentAccount = null;
        this.option.paymentAccount = [];
        response.data.paymentAccount.map((row) => {
          this.option.paymentAccount.push(row);
        });
        this.$vs.loading.close();
      });
    },
  },
};
</script>